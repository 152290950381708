import {forgotPasswordAPI, postLoginInfo} from '../services/token-api';
import {getDealerServiceData} from '../services/DealerService'
import {getProductServiceData} from '../services/FaqServices'
import {
    activateBillingProfileAPI,
    AXIOS_1,
    callGetPayloadFailureDetails,
    callLogViewerSearch,
    callPayloadLookupDownloadPayloadXML,
    callPayloadLookupPayloadXML,
    callPayloadLookupSearch,
    changePasswordAPI,
    changePasswordPermissionAPI,
    changeUserLanguageAPI,
    deImpersonateUserByLoginAPI,
    deletePreferencesLeadSettingsDetailsAPI,
    deleteResourceAPI,
    downloadLeadsADFFormatAPI,
    downloadZipBulkImportAPI,
    exportAdjustCoverageZipAPI,
    exportManageEnrolmentSearcheddataAPI,
    exportPayloadResultsXLS_API,
    findDispositionDetailsAPI,
    getDealerCommunicationDetailAPI,
    getAddonManageEnrollmentAPI,
    getAddonProgramManageEnrollmentAPI,
    getAdjustCoverageLeadSettingsDetailsAPI,
    getAllAddonDetailsAPI,
    getAvailableComplianceDownloadsAPI,
    getBillingProfileDetailsAPI,
    getChangeableSubscriptions,
    getCityZipListAPI,
    getComplianceCategoriesAPI,
    getCrmAPI,
    getDealerDataByDistrictAPI,
    getDealerEnrollmentData,
    getDealerEnrollmentDetailsAPI,
    getDealersLeadCoverageAPI,
    getDealerSubscriptionsAPI,
    getDistrictListFilterAPI,
    getFilteredProductsList,
    getHeaderMenuDataAPI,
    getLeadRecordAPI,
    getLeadResponseTimeAPI,
    getLeadSalesDetailsAPI,
    getLeadsFilterOptionsAPI,
    getLeadsSearchAPI,
    getManageEnrolmentFilterOptionsAPI,
    getManageWebsiteProgramsAPI,
    getManageWebsiteProgramsFilterAPI,
    getManageWebsiteProgramsReportAPI,
    getOEMsAPI,
    getOfferProvidersAPI,
    getOfferProvidersPDFAPI,
    getPageLogViewerWithParams,
    getPagePayloadLookupWithParams,
    getPreferencesLeadSettingsDetailsAPI,
    getProductPricingDetailAPI,
    getProgramAddonsAPI,
    getProviderInitialPageDataAPI,
    getRadiusCityAPI,
    getRegionListFilterAPI,
    getRequestCreditReasonAPI,
    getResourcesFilterOptionsAPI,
    getSelectedDealerBudgetDetailsAPI,
    getSelectedDealerLeadSettingsDetailsAPI,
    getSelectedDealerProgramDetailsAPI,
    getSmartleadServiceAddonCustomParameterDetailsAPI,
    getAddonCustomParametersToCopyAPI,
    getSmartleadServiceAddonModelsAPI,
    getSubscriptionOptions,
    getUserInformation,
    getUserProfileContactDetailsAPI,
    getUserProfileDetailsAPI,
    getUsersToImpersonateApi,
    getVehicleManagementAPI,
    getZipCodeStringByRadiousAPI,
    impersonateUserByLoginAPI,
    notificationsUnsubscriptionAPI,
    otherUpdateDealerBusinessTimeEnrollmentDetailsAPI,
    otherUpdateDealerEnrollmentDetailsAPI,
    postChangeSubscriptions,
    postManageSubscriptions,
    postRequestCreditReasonAPI,
    saveAddonParametersAPI,
    saveBillingProfileAPI,
    saveDealersLeadCoverageAPI,
    savePreferencesLeadSettingsDetailsAPI,
    saveUserProfileContactDetailsAPI,
    saveUserProfileDetailsAPI,
    saveVehicleManagementAPI,
    searchManageEnrolmentDataAPI,
    setManageProgramActionAPI,
    submitContactFormAPI,
    submitResponseTimeOverrideAPI,
    triggerComplianceReportsAPI,
    updateAddonCustomParametersAPI,
    subscribeAddonWithCopiedDataAPI,
    updateDealerEnrollmentDetailsAPI,
    updateDealerSubscriptionCustomParamsAPI,
    updatePortalNotificationSubscriptionAPI,
    updateProgramStatusAPI,
    updateSelectedDealerBudgetDetailsAPI
} from '../services/CommanServices'
import {
    exportReportsDataAPI,
    exportReportsRecentFullyConvertedDealerRankingAPI,
    generateReportAPI,
    generateReportDetailsAPI,
    getAffinityDealerSalesReportAPI,
    getConciergeReportDataPageAPI,
    getPeriodForReportAPI,
    getRedirectToCalldripUrlAPI,
    getReportFormAPI,
    getReportsFilterAPI,
    getShopperContactReportDataPageAPI,
    getThresholdsAPI,
    redirectToCalldripSsoAPI
} from '../services/ReportsServices';
import {
    getDealerEnrollmentDataAPI,
    getDealerEnrollmentDataInfoAPI,
    getManageEnrolmentDownloadManagerDataAPI,
    postDealerEnrollmentDataAPI,
    sendDealerProviderInterestNotificationAPI
} from '../services/SetupEnrollmentServices';
import {
    complianceDealerReportAPI,
    createNewReportAPI,
    generateComplianceDataRecordAPI,
    generateComplianceScoresReportAPI,
    getComplianceAggregateReportDetailsAPI,
    getComplianceDealerReportDetailsAPI,
    getComplianceOEMsAPI,
    getCrmComplianceAggregateReportDetailsAPI,
    getCrmNonCompliantReportDetailsAPI,
    getDealerReportFilterAPI,
    getNonCompliantReportDetailsAPI,
    getOEMRegionsDetailsAPI,
    getRegionsDistrictsDetailsAPI,
    getZipCodeBulkImportResultAPI,
    prepareCreateCrmComplianceReportAPI,
    prepareCreateReportAPI,
    removeUploadFileAPI,
    updateViewedReportAPI,
    uploadFilesAPI,
    zipCodeBulkImportAPI,
    zipCodeBulkImportSaveAPI
} from '../services/ComplianceServices';
import {discardCmsChangesAPI, getPageCmsAPI, publishCmsChangesAPI, saveCmsDraftAPI, uploadMediaAPI} from '../services/CmsServices';
import auth from '../auth/authService';
import router from '../router.js';
import {store} from "@/store";

export const actions = {
    // Using for Login API Call
    loginMain({commit}, {user, password, rememberMe}) {
        return postLoginInfo(user, password, rememberMe)
            .then(response => {
                commit('updateLoginAPIErrors', null);
                if (response.status === 200) {
                    // place the loginSuccess state into our vuex store
                    let respData = response.data;
                    commit('login_success', {
                        userName: user,
                        userPass: password,
                        tokenData: respData
                    });
                    auth.handleAuthentication(respData);
                }
            })
            .catch((error) => {
                // commit('updateAPIErrors', error);
                commit('updateLoginAPIErrors', null);
                if (error.response.status === 401) {
                    commit('updateLoginAPIErrors', error.response);
                }
            });
    },
    async loadDWCData({commit}, oem) {
        let data = {}
        data.oemName = oem;
        getDealerServiceData(data).then(response => {
            commit('setDWCData', response.data);
        });
    },
    updatePriceComparisonData({
                                  commit
                              }, data) {
        commit('setPriceComparisonData', data);
    },
    async loadHeaderMenuAPI({commit}) {
        return getHeaderMenuDataAPI()
            .then(response => {
                if (response.status === 200) {
                    let respData = response.data;
                    commit('setHeaderMenuData', respData); // mainMenu);
                } else {
                    commit('updateAPIErrors', response.data);
                }
            })
            .catch((error) => {
                commit('updateAPIErrors', error);
            });
    },
    setLoading({commit}, data) {
        commit('updateLoader', data);
    },
    saveEnrolmentDetails({commit}, data) {
        commit('saveEnrolmentData', data);
    },
    updateErrorState({commit}) {
        commit('updateAPIErrors', null);
    },
    async loadUserDetails({commit}) {
        return getUserInformation()
            .then(response => {
                localStorage.setItem("user_data", JSON.stringify(response.data));
                commit('updateUserDetails', response.data);
            })
            .catch(error => {
                commit('updateAPIErrors', error);
            })
    },
    async loadProductData({commit}, oem) {
        return getProductServiceData(oem)
            .then(response => {
                commit('updateProductsData', response.data);
            })
            .catch(error => {
                commit('updateAPIErrors', error);
            })
    },
    sessionExpired({commit}, value) {
        commit('updateSessionExpired', value);
    },
    async forgorPassword({commit}, data) {
        return forgotPasswordAPI(data)
            .then(response => {
                if (response.status === 200) {
                    commit('updateForgotPass', response.data);
                }
            })
            .catch(error => {
                commit('updateAPIErrors', error);
            });
    },
    resetStateValue({commit}, data) {
        commit('resetStateByName', data)
    },
    getProperLeadSources({commit}, data) {
        return new Promise((resolve) => {
            AXIOS_1.get(`/api/portal/leads/properLeadSources`, {params: data})
                .then(response => resolve(response))
                .catch(error => {
                    store.dispatch('dwc/setLoading', false);
                    commit('updateAPIErrors', error);
                });
        });
    },
    getAllDealerDetails({commit}, queryString) {
        return new Promise((resolve) => {
            AXIOS_1.get(`/api/portal/dealers${queryString}`)
                .then(response => resolve(response))
                .catch(error => {
                    store.dispatch('dwc/setLoading', false);
                    commit('updateAPIErrors', error);
                });
        });
    },
    getAllComplianceReportDealers({commit}, queryString) {
        return new Promise((resolve) => {
            AXIOS_1.get(`/api/portal/compliance/dealers${queryString}`)
                .then(response => resolve(response))
                .catch(error => {
                    store.dispatch('dwc/setLoading', false);
                    commit('updateAPIErrors', error);
                });
        });
    },
    getAllAddonDetails({commit}, queryString) {
        return getAllAddonDetailsAPI(queryString)
            .then(response => {
                if (response.status === 200) {
                    commit('updateAllAddonDetails', response.data);
                }
            })
            .catch(error => {
                commit('updateAPIErrors', error);
            });
    },
    getUsersToImpersonate(_, queryString) {
        return getUsersToImpersonateApi(queryString);
    },
    impersonateUserByLogin({commit}, data) {
        return impersonateUserByLoginAPI(data).then(response => {
            commit('resetAllStates', response);
        });
    },
    deImpersonateUserByLogin({commit}, data) {
        return deImpersonateUserByLoginAPI(data).then(response => {
            commit('resetAllStates', response);
        });
    },
    getOEMs({commit}) {
        return getOEMsAPI()
            .then(response => {
                if (response.status === 200) {
                    commit('updateAllOEMs', response.data);
                }
            })
            .catch(error => {
                commit('updateAPIErrors', error);
            });
    },
    getComplianceOEMs(_) {
        return getComplianceOEMsAPI()
    },
    uploadMedia(_, data) {
        return uploadMediaAPI(data)
    },
    saveCmsDraft(_, data) {
        return saveCmsDraftAPI(data)
    },
    publishCmsChanges(_, data) {
        return publishCmsChangesAPI(data)
    },
    discardCmsChanges(_, data) {
        return discardCmsChangesAPI(data)
    },
    getPageCms(_, data) {
        return getPageCmsAPI(data)
    },
    getOfferProviders(_, data) {
        return getOfferProvidersAPI(data)
    },
    getAvailableComplianceDownloads(_, data) {
        return getAvailableComplianceDownloadsAPI(data)
    },
    triggerComplianceReports(_, data) {
        return triggerComplianceReportsAPI(data)
    },
    getCrm(_, oemId) {
        return getCrmAPI(oemId)
    },
    getComplianceCategories(_, data) {
        return getComplianceCategoriesAPI(data)
    },
    getSelectedDealerProgramDetails({commit}, dealerCode) {
        return getSelectedDealerProgramDetailsAPI(dealerCode)
            .then(response => {
                if (response.status === 200) {
                    commit('updateSelectedDealerProgramDetails', response.data);
                }
            })
            .catch(error => {
                commit('updateAPIErrors', error);
            });
    },
    getLeadSalesDetails({commit}, data) {
        return getLeadSalesDetailsAPI(data)
            .then(response => {
                if (response.status === 200) {
                    commit('updateLeadSalesDetails', response.data);
                }
            })
            .catch(error => {
                commit('updateAPIErrors', error);
            });
    },
    getDealerDataByDistrict({commit}, data) {
        return getDealerDataByDistrictAPI(data)
            .then(response => {
                if (response.status === 200) {
                    commit('updateOtherDealersData', response.data);
                }
            })
            .catch(error => {
                commit('updateAPIErrors', error);
            });
    },
    getProviderInitialPageData({commit}, data) {
        return getProviderInitialPageDataAPI(data)
    },
    getSelectedDealerBudgetDetails({commit}, data) {
        return getSelectedDealerBudgetDetailsAPI(data)
            .then(response => {
                if (response.status === 200) {
                    commit('updateSelectedDealerBudgetDetails', response.data);
                }
            })
            .catch(error => {
                commit('updateAPIErrors', error);
            });
    },
    updateSelectedDealerBudgetDetails({commit}, data) {
        return updateSelectedDealerBudgetDetailsAPI(data)
            .then(response => {
                if (response.status === 200) {
                    commit('updateSuccessAPIResponse', 'Budget updated successfully.');
                    commit('updateSuccessAPIResponseError', null);
                }
            })
            .catch(error => {
                commit('updateSuccessAPIResponse', null);
                commit('updateSuccessAPIResponseError', error.response.data);
                commit('updateAPIErrors', error);
            });
    },
    // Set Budget API End Here
    // Lead Settings API Started
    getSelectedDealerLeadSettingsDetails({commit}, data) {
        return getSelectedDealerLeadSettingsDetailsAPI(data)
            .then(response => {
                if (response.status === 200) {
                    commit('updateSelectedDealerLeadSettingsDetails', response.data);
                }
            })
            .catch(error => {
                commit('updateAPIErrors', error);
            });
    },
    getAdjustCoverageLeadSettingsDetails({commit}, data) {
        return getAdjustCoverageLeadSettingsDetailsAPI(data)
            .then(response => {
                if (response.status === 200) {
                    commit('updateAdjustCoverageLeadSettingsDetails', response.data);
                }
            })
            .catch(error => {
                commit('updateAPIErrors', error);
            });
    },
    getDealersLeadCoverage(_, data) {
        return getDealersLeadCoverageAPI(data)
    },
    saveDealersLeadCoverage(_, data) {
        return saveDealersLeadCoverageAPI(data)
    },
    getZipCodeStringByRadious(_, data) {
        return getZipCodeStringByRadiousAPI(data);
    },
    exportAdjustCoverageZip({commit}, data) {
        return exportAdjustCoverageZipAPI(data)
            .then(response => {
                if (response.status === 200) {
                    const dt = new Date();
                    const month = dt.toLocaleString('en-US', {month: 'short'});
                    const day = dt.toLocaleString('en-US', {weekday: 'short'});
                    const year = dt.getUTCFullYear();
                    const date = dt.getUTCDate();
                    let filename = "";
                    let disposition = response.headers['content-disposition'];
                    if (disposition && disposition.indexOf('attachment') !== -1) {
                        let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                        let matches = filenameRegex.exec(disposition);
                        if (matches != null && matches[1]) {
                            filename = matches[1].replace(/['"]/g, '');
                        }
                    }

                    if (filename) {
                        filename = filename.replace(/:/g, '_');
                    }
                    if (typeof window.navigator.msSaveBlob !== 'undefined') {
                        let blobData = response.data;
                        let blob = new Blob([blobData], {type: 'application/octet-stream'});
                        window.navigator.msSaveBlob(blob, filename);
                    } else {
                        const blobURL = window.URL.createObjectURL(new Blob([response.data], {type: 'application/octet-stream'}))
                        const tempLink = document.createElement('a');
                        tempLink.href = blobURL;

                        if (!filename) {
                            tempLink.setAttribute('download', `Enrollments-${day}_${month}_${date}_05_41_45_CDT_${year}.csv`);
                        } else {
                            tempLink.setAttribute('download', filename);
                        }
                        if (typeof tempLink.download === 'undefined') {
                            tempLink.setAttribute('target', '_blank');
                        }

                        document.body.appendChild(tempLink);
                        tempLink.click();
                        document.body.removeChild(tempLink);
                        window.URL.revokeObjectURL(blobURL);
                    }
                }
            })
            .catch(error => {
                commit('updateAPIErrors', error);
            })
    },
    downloadZipBulkImport({commit}, data) {
        return downloadZipBulkImportAPI(data)
            .then(response => {
                if (response.status === 200) {
                    const dt = new Date();
                    const month = dt.toLocaleString('en-US', {month: 'short'});
                    const day = dt.toLocaleString('en-US', {weekday: 'short'});
                    const year = dt.getUTCFullYear();
                    const date = dt.getUTCDate();
                    let filename = "";
                    let disposition = response.headers['content-disposition'];
                    if (disposition && disposition.indexOf('attachment') !== -1) {
                        let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                        let matches = filenameRegex.exec(disposition);
                        if (matches != null && matches[1]) {
                            filename = matches[1].replace(/['"]/g, '');
                        }
                    }

                    if (filename) {
                        filename = filename.replace(/:/g, '_');
                    }
                    if (typeof window.navigator.msSaveBlob !== 'undefined') {
                        let blobData = response.data;
                        let blob = new Blob([blobData], {type: 'application/octet-stream'});
                        window.navigator.msSaveBlob(blob, filename);
                    } else {
                        const blobURL = window.URL.createObjectURL(new Blob([response.data], {type: 'application/octet-stream'}))
                        const tempLink = document.createElement('a');
                        tempLink.href = blobURL;

                        if (!filename) {
                            tempLink.setAttribute('download', `zip-import-${day}_${month}_${year}.csv`);
                        } else {
                            tempLink.setAttribute('download', filename);
                        }
                        if (typeof tempLink.download === 'undefined') {
                            tempLink.setAttribute('target', '_blank');
                        }

                        document.body.appendChild(tempLink);
                        tempLink.click();
                        document.body.removeChild(tempLink);
                        window.URL.revokeObjectURL(blobURL);
                    }
                }
            })
            .catch(error => {
                commit('updateAPIErrors', error);
            })
    },
    getRadiusCity(_, data) {
        return getRadiusCityAPI(data);
    },
    getCityZipList(_, data) {
        return getCityZipListAPI(data);
    },
    getPreferencesLeadSettingsDetails({commit}, data) {
        return getPreferencesLeadSettingsDetailsAPI(data)
            .then(response => {
                if (response.status === 200) {
                    commit('updatePreferencesLeadSettingsDetails', response.data);
                }
            })
            .catch(error => {
                commit('updateAPIErrors', error);
            });
    },
    updateLeadPreferencesNewDestination({commit}, data) {
        commit('updateLeadPreferencesNewDestination', data);
    },
    savePreferencesLeadSettingsDetails({commit}, data) {
        return savePreferencesLeadSettingsDetailsAPI(data)
            .then(response => {
                if (response.status === 200) {
                    //commit('updatePreferencesLeadSettingsDetails', response.data);
                }
            })
            .catch(error => {
                commit('updateAPIErrors', error);
            });
    },
    getVehicleManagement({commit}, data) {
        return getVehicleManagementAPI(data)
            .then(response => {
                if (response.status === 200) {
                    commit('updateVehicleManagement', response.data);
                }
            })
            .catch(error => {
                commit('updateAPIErrors', error);
            })
    },
    saveVehicleManagement({commit}, data) {
        return saveVehicleManagementAPI(data)
            .then(response => {
                if (response.status === 200) {
                    commit('updateSuccessAPIResponse', 'Vehicle Management updated successfully.');
                }
            })
            .catch(error => {
                commit('updateAPIErrors', error);
            })
    },
    // Lead Settings API end Here
    // Manage Enrollment API Call Start Here
    getManageEnrolmentFilterOptions({commit}, data) {
        return getManageEnrolmentFilterOptionsAPI(data)
            .then(response => {
                if (response.status === 200) {
                    commit('updateManageEnrolmentFilterOptions', response.data);
                }
            })
            .catch(error => {
                commit('updateAPIErrors', error);
            })
    },
    searchManageEnrolmentData({commit}, data) {
        return searchManageEnrolmentDataAPI(data)
            .then(response => {
                if (response.status === 200) {
                    commit('updateSearchManageEnrolmentData', response.data);
                }
            })
            .catch(error => {
                commit('updateAPIErrors', error);
            })
    },
    exportManageEnrolmentSearcheddata({commit}, data) {
        return exportManageEnrolmentSearcheddataAPI(data)
            .then(response => {
                if (response.status === 200) {
                    const dt = new Date();
                    const month = dt.toLocaleString('en-US', {month: 'short'});
                    const day = dt.toLocaleString('en-US', {weekday: 'short'});
                    const year = dt.getUTCFullYear();
                    const date = dt.getUTCDate();
                    let filename = "";
                    let disposition = response.headers['content-disposition'];
                    if (disposition && disposition.indexOf('attachment') !== -1) {
                        let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                        let matches = filenameRegex.exec(disposition);
                        if (matches != null && matches[1]) {
                            filename = matches[1].replace(/['"]/g, '');
                        }
                    }

                    if (filename) {
                        filename = filename.replace(/:/g, '_');
                    }
                    if (typeof window.navigator.msSaveBlob !== 'undefined') {
                        let blobData = response.data;
                        let blob = new Blob([blobData], {type: 'application/octet-stream'});
                        window.navigator.msSaveBlob(blob, filename);
                    } else {
                        const blobURL = window.URL.createObjectURL(new Blob([response.data], {type: 'application/octet-stream'}))
                        const tempLink = document.createElement('a');
                        tempLink.href = blobURL;

                        if (!filename) {
                            tempLink.setAttribute('download', `Enrollments-${day}_${month}_${date}_05_41_45_CDT_${year}.csv`);
                        } else {
                            tempLink.setAttribute('download', filename);
                        }
                        if (typeof tempLink.download === 'undefined') {
                            tempLink.setAttribute('target', '_blank');
                        }

                        document.body.appendChild(tempLink);
                        tempLink.click();
                        document.body.removeChild(tempLink);
                        window.URL.revokeObjectURL(blobURL);
                    }
                }
            })
            .catch(error => {
                commit('updateAPIErrors', error);
            })
    },
    getOemProgram({commit}, data) {
        commit('updateProgramIds', data);
    },
    getProgramAddons({commit}, data) {
        return getProgramAddonsAPI(data)
            .then(response => {
                if (response.status === 200) {
                    commit('updateAddonsIds', response.data);
                }
            })
            .catch(error => {
                commit('updateAPIErrors', error);
            })
    },
    // Manage Enrollment API Call End Here
    // ViewLeads API Call Start Here 
    getLeadsFilterOptions({commit}, data) {
        return getLeadsFilterOptionsAPI(data)
            .then(response => {
                if (response.status === 200) {
                    commit('updateLeadsFilterOptions', response.data);
                }
            })
            .catch(error => {
                commit('updateAPIErrors', error);
            })
    },
    getViewLeadsSearch({commit}, data) {
        return getLeadsSearchAPI(data)
            .then(response => {
                if (response.status === 200) {
                    commit('updateViewLeadsSearch', response.data);
                }
            })
            .catch(error => {
                commit('updateAPIErrors', error);
            })
    },
    // ViewLeads API Call End Here

    //LogViewer Code Starts Here
    //Get Page With Params
    loadGetPageLogViewerWithParams({commit}, data) {
        return getPageLogViewerWithParams(data)
            .then(response => {
                if (response.status === 200) {
                    const withLabel = ['None'];
                    withLabel.push.apply(withLabel, response.data.eventTypes);
                    response.data.eventTypes = withLabel;
                    commit('updateGetLogViewerPageData', response.data);
                }
            })
            .catch(error => {
            })
    },
    //Execute Search
    executeLogViewerSearchAPI({commit}, data) {
        data.payload.eventType = data.payload.eventType === 'None' ? '' : data.payload.eventType;
        return callLogViewerSearch(data)
            .then(response => {
                if (response.status === 200) {
                    commit('updateLogViewerSearchData', response.data);
                }
            })
            .catch(error => {
            })
    },
    //LogViewer Code Ends Here
    //Payload Lookup Code Starts Here
    //Get Page With Params
    loadGetPagePayloadLookupWithParams({commit}, data) {
        return getPagePayloadLookupWithParams(data)
            .then(response => {
                if (response.status === 200) {
                    commit('updateGetPageData', response.data);
                }
            })
            .catch(error => {
                commit('updateAPIErrors', error);
            })
    },
    //Execute Search
    executePayloadLookupSearchAPI({commit}, data) {
        return callPayloadLookupSearch(data)
            .then(response => {
                if (response.status === 200) {
                    commit('updatePayloadLookupSearchData', response.data);
                }
            })
            .catch(error => {
                commit('updateAPIErrors', error);
            })
    },
    // Show Incoming/Outgoing Payload Failure Details
    executeShowPayloadFailureDetails({commit}, data) {
        return callGetPayloadFailureDetails(data)
            .then(response => {
                if (response.status === 200) {
                    commit('updatePayloadFailureDetails', response.data);
                }
            })
            .catch(error => {
                commit('updateAPIErrors', error);
            })
    },
    executeClearPayloadFailureDetails({commit}) {
        commit('updatePayloadFailureDetails', null);
    },
    //Open Incoming/Outgoing Payload XML
    executePayloadLookupPayloadXMLAPI({commit}, data) {
        return callPayloadLookupPayloadXML(data)
            .then(response => {
                if (response.status === 200) {
                    commit('updatePayloadLookupPayloadXMLData', response.data);
                }
            })
            .catch(error => {
                commit('updateAPIErrors', error);
            })
    },
    //Download Incoming/Outgoing Payload XML
    downloadPayloadLookupPayloadXMLAPI({commit}, data) {
        return callPayloadLookupDownloadPayloadXML(data)
            .then(response => {
                if (response.status === 200) {
                    let filename = '';
                    let disposition = response.headers['content-disposition'];
                    if (disposition && disposition.indexOf('attachment') !== -1) {
                        let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                        let matches = filenameRegex.exec(disposition);
                        if (matches != null && matches[1]) {
                            filename = matches[1].replace(/['"]/g, '');
                        }
                    }

                    if (filename) {
                        filename = filename.replace(/:/g, '_');
                    }
                    if (typeof window.navigator.msSaveBlob !== 'undefined') {
                        let blobData = response.data;
                        let blob = new Blob([blobData], {type: 'application/octet-stream'});
                        window.navigator.msSaveBlob(blob, filename);
                    } else {
                        const blobURL = window.URL.createObjectURL(new Blob([response.data], {type: 'application/octet-stream'}))
                        const tempLink = document.createElement('a');
                        tempLink.href = blobURL;

                        if (!filename) {
                            var responseURLArray = response.config.url.split('&');
                            var payloadLookupTypeTextToFind = 'payloadLookupType=';
                            var payloadLookupTypeMatch = responseURLArray.filter(function (responseURLArrayEach) {
                                if (responseURLArrayEach) {
                                    return (responseURLArrayEach.substring(0, payloadLookupTypeTextToFind.length) === payloadLookupTypeTextToFind);
                                }
                            });
                            var payloadLookupType = payloadLookupTypeMatch[0].split('=')[1];

                            var payloadLookupDocumentTextToFind = 'documentId=';
                            var payloadLookupDocumentMatch = responseURLArray.filter(function (responseURLArrayEach) {
                                if (responseURLArrayEach) {
                                    return responseURLArrayEach.indexOf(payloadLookupDocumentTextToFind) >= 0;
                                }
                            });
                            var documentID = payloadLookupDocumentMatch[0].split('=')[1];

                            tempLink.setAttribute('download', 'Lead_' + payloadLookupType + '_' + documentID + '.xml');
                        } else {
                            tempLink.setAttribute('download', filename);
                        }
                        if (typeof tempLink.download === 'undefined') {
                            tempLink.setAttribute('target', '_blank');
                        }

                        document.body.appendChild(tempLink);
                        tempLink.click();
                        document.body.removeChild(tempLink);
                        window.URL.revokeObjectURL(blobURL);
                    }
                }
            })
            .catch(error => {
                commit('updateAPIErrors', error);
            })
    },

    exportPayloadResultsXLS({commit}, data) {
        return exportPayloadResultsXLS_API(data)
            .then(response => {
                if (response.status === 200) {
                    const dt = new Date();
                    const month = dt.toLocaleString('en-US', {month: 'short'});
                    const day = dt.toLocaleString('en-US', {weekday: 'short'});
                    const year = dt.getUTCFullYear();
                    const date = dt.getUTCDate();
                    let filename = "";
                    let disposition = response.headers['content-disposition'];
                    if (disposition && disposition.indexOf('attachment') !== -1) {
                        let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                        let matches = filenameRegex.exec(disposition);
                        if (matches != null && matches[1]) {
                            filename = matches[1].replace(/['"]/g, '');
                        }
                    }

                    if (filename) {
                        filename = filename.replace(/:/g, '_');
                    }
                    if (typeof window.navigator.msSaveBlob !== 'undefined') {
                        let blobData = response.data;
                        let blob = new Blob([blobData], {type: 'application/octet-stream'});
                        window.navigator.msSaveBlob(blob, filename);
                    } else {
                        const blobURL = window.URL.createObjectURL(new Blob([response.data], {type: 'application/octet-stream'}));
                        const tempLink = document.createElement('a');
                        tempLink.href = blobURL;

                        if (!filename) {
                            tempLink.setAttribute('download', `Payload-${day}_${month}_${date}_${year}.${data.type.toLowerCase()}`);
                        } else {
                            tempLink.setAttribute('download', filename);
                        }
                        if (typeof tempLink.download === 'undefined') {
                            tempLink.setAttribute('target', '_blank');
                        }

                        document.body.appendChild(tempLink);
                        tempLink.click();
                        document.body.removeChild(tempLink);
                        window.URL.revokeObjectURL(blobURL);
                    }
                }
            })
            .catch(error => {
                commit('updateAPIErrors', error);
            })
    },

    //Payload Lookup Code Ends Here

    // Change Password API call start here 
    checkChangePasswordPermission({commit}) {
        return changePasswordPermissionAPI()
            .then(response => {
                if (response.status === 200) {
                    commit('UpdateChangePasswordPermission', response.data);
                }
            })
            .catch(error => {
                commit('updateAPIErrors', error);
            })
    },

    changePassword({commit}, data) {
        return changePasswordAPI(data)
            .then(response => {
                if (response.status === 200) {
                    commit('UpdateChangePassword', 'Updated');
                    commit('UpdateChangePasswordError', null);
                }
            })
            .catch(error => {
                commit('UpdateChangePassword', null);
                commit('UpdateChangePasswordError', error.response.data);
                commit('updateAPIErrors', error);
            })
    },
    // Change Password API call end here

    changeUserLanguage(_, data) {
        return changeUserLanguageAPI(data);
    },

    // Reports API Call Start Here
    generateReport({commit}, data) {
        return generateReportAPI(data)
            .then(response => {
                if (response.status === 200) {
                    commit('UpdateGenerateReport', response.data);
                    commit('UpdateGenerateReportError', null);
                }
            })
            .catch(error => {
                commit('UpdateGenerateReport', null);
                commit('UpdateGenerateReportError', error.response.data);
                commit('updateAPIErrors', error);
            })
    },
    getReportsFilter({commit}, data) {
        return getReportsFilterAPI(data)
            .then(response => {
                if (response.status === 200) {
                    commit('UpdateReportFilterDetails', response.data);
                }
            })
            .catch(error => {
                commit('updateAPIErrors', error);
            })
    },
    getReportForm({commit}, data) {
        commit('UpdateGenerateReport', null);
        return getReportFormAPI(data)
            .then(response => {
                if (response.status === 200) {
                    commit('UpdateReportFormDetails', response.data);
                }
            })
            .catch(error => {
                commit('updateAPIErrors', error);
            })
    },
    redirectToCalldripSso({commit}, dealerId) {
        return redirectToCalldripSsoAPI(dealerId)
            .then(response => {
                if (response.status === 200) {
                    commit('RedirectToCalldripSso', response.data);
                }
            })
            .catch(error => {
                commit('updateAPIErrors', error);
            })
    },
    getRedirectToCalldripUrl({commit}) {
        return getRedirectToCalldripUrlAPI()
            .then(response => {
                if (response.status === 200) {
                    commit('GetRedirectToCalldripUrl', response.data);
                }
            })
            .catch(error => {
                commit('updateAPIErrors', error);
            })
    },
    exportReport({commit}, data) {
        return exportReportsDataAPI(data)
            .then(response => {
                if (response.status === 200) {
                    let filename = "";
                    let disposition = response.headers['content-disposition'];
                    if (disposition && disposition.indexOf('attachment') !== -1) {
                        let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                        let matches = filenameRegex.exec(disposition);
                        if (matches != null && matches[1]) {
                            filename = matches[1].replace(/['"]/g, '');
                        }
                    }
                    if (filename) {
                        filename = filename.replace(/:/g, '_');
                    }
                    const contentType = response.headers['content-type'];
                    let blob = new Blob([response.data], {type: contentType, charset: "UTF-8"});
                    if (typeof window.navigator.msSaveBlob !== 'undefined') {
                        window.navigator.msSaveBlob(blob, filename);
                    } else {
                        const blobURL = window.URL.createObjectURL(blob);
                        // window.open(blobURL);
                        const tempLink = document.createElement('a');
                        tempLink.href = blobURL;
                        if (!filename) {
                            tempLink.setAttribute('download', `${data.reportName}.${data.exportFormat}`);
                        } else {
                            tempLink.setAttribute('download', filename);
                        }
                        if (typeof tempLink.download === 'undefined') {
                            tempLink.setAttribute('target', '_blank');
                        }
                        document.body.appendChild(tempLink);
                        tempLink.click();
                        document.body.removeChild(tempLink);
                        window.URL.revokeObjectURL(blobURL);
                    }
                }
            })
            .catch(error => {
                commit('updateAPIErrors', error);
            })
    },
    getConciergeReportDataPage({commit}, data) {
        return getConciergeReportDataPageAPI(data)
            .then(response => {
                if (response.status === 200) {
                    let respData = [];
                    let key = 'conciergeReportData';
                    respData = response.data && response.data.conciergeReportData;
                    if (data.reportName === 'ShopperContactAggregatedSummaryReport' || data.reportName === 'ShopperContactDealerSummaryReport') {
                        respData = response.data && response.data.commonReport;
                        key = 'commonReport';
                    }
                    const finalData = {
                        key,
                        value: respData
                    };
                    commit('UpdateCheckGenerateReport', finalData);
                    commit('UpdateGenerateReportError', null);
                }
            })
            .catch(error => {
                commit('UpdateCheckGenerateReport', null);
                commit('UpdateGenerateReportError', error.response.data);
                commit('updateAPIErrors', error);
            })
    },

    getShopperContactReportDataPage({commit}, data) {
        return getShopperContactReportDataPageAPI(data)
            .then(response => {
                if (response.status === 200) {
                    let respData = [];
                    let key = 'conciergeReportData';
                    respData = response.data && response.data.conciergeReportData;
                    if (data.reportName === 'ShopperContactAggregatedSummaryReport' || data.reportName === 'ShopperContactDealerSummaryReport') {
                        let reportData = response.data && response.data.commonReport;
                        if (data.reportName === 'ShopperContactAggregatedSummaryReport') {
                            respData = reportData && reportData.dealerAggregatedReportData;
                            key = 'dealerAggregatedReportData';
                        } else {
                            respData = reportData && reportData.dealerReportData;
                            key = 'dealerReportData';
                        }
                    }
                    const finalData = {
                        key,
                        value: respData
                    };
                    commit('UpdateCheckShopperContactGenerateReport', finalData);
                    commit('UpdateGenerateReportError', null);
                }
            })
            .catch(error => {
                commit('UpdateGenerateReport', null);
                commit('UpdateGenerateReportError', error.response.data);
                commit('updateAPIErrors', error);
            })
    },
    // Reports API Call End Here
    //Manage Profile Code Starts Here - API Call
    loadUserProfileDetails({commit}) {
        return getUserProfileDetailsAPI()
            .then(response => {
                commit('updateUserProfileDetails', response.data);
            })
            .catch(error => {
                commit('updateAPIErrors', error);
            });
    },
    saveProfileInformation({commit}, data) {
        return saveUserProfileDetailsAPI(data)
            .then(response => {
                commit('updateUserProfileErrors', null);
                if (response.status === 200) {
                    // commit('updateUserProfileDetails', response.data);
                }
            })
            .catch(error => {
                if (error.response && (error.response.status === 400 || error.response.status >= 500)) {
                    commit('updateUserProfileErrors', error.response.data);
                } else {
                    commit('updateUserProfileErrors', null);
                    commit('updateAPIErrors', error);
                }
            });
    },
    loadBillingProfile({commit}, data) {
        return getBillingProfileDetailsAPI(data)
            .then(response => {
                commit('updateBillingProfileDetails', response.data);
            })
            .catch(error => {
                commit('updateAPIErrors', error);
            });
    },
    saveBillingProfile({commit}, data) {
        return saveBillingProfileAPI(data)
            .then(() => {
                commit('updateUserProfileErrors', null);
            })
            .catch(error => {
                if (error.response && (error.response.status === 400 || error.response.status >= 500)) {
                    commit('updateUserProfileErrors', error.response.data);
                } else {
                    commit('updateUserProfileErrors', null);
                    commit('updateAPIErrors', error);
                }
            });
    },
    activateBillingProfile({commit}, data) {
        return activateBillingProfileAPI(data)
            .then(() => {
                commit('updateUserProfileErrors', null);
            })
            .catch(error => {
                if (error.response && (error.response.status === 400 || error.response.status >= 500)) {
                    commit('updateUserProfileErrors', error.response.data);
                } else {
                    commit('updateUserProfileErrors', null);
                    commit('updateAPIErrors', error);
                }
            });
    },
    //Manage Profile Code Ends Here

    //Manage Profile contact over modal Code Starts Here - API Call
    getUserProfileContactDetails({commit}) {
        return getUserProfileContactDetailsAPI()
            .then(response => {
                commit('updateUserProfileContactDetails', response.data);
            })
            .catch(error => {
                commit('updateAPIErrors', error);
            });
    },
    saveProfileContactDetails({commit}, data) {
        return saveUserProfileContactDetailsAPI(data)
            .then(response => {
                commit('updateUserProfileErrors', null);
                if (response.status === 200) {
                    // commit('updateUserProfileDetails', response.data);
                }
            })
            .catch(error => {
                if (error.response && (error.response.status === 400 || error.response.status >= 500)) {
                    commit('updateUserProfileErrors', error.response.data);
                } else {
                    commit('updateUserProfileErrors', null);
                    commit('updateAPIErrors', error);
                }
            });
    },
    //Manage Profile contact over modal Code Ends Here

    // Manage website and packages start here
    getDealerEnrollmentData({commit}, data) {
        return getDealerEnrollmentData(data)
            .then(response => {
                if (response.status === 200) {
                    commit('getEnrollmentData', response.data);
                }
            })
            .catch(error => {
                commit('updateAPIErrors', error);
            })
    },

    getManageWebsitePrograms({commit}, filterData) {
        return getManageWebsiteProgramsAPI(filterData)
            .then(response => {
                if (response.status === 200) {
                    commit('getManageWebsitePrograms', response.data);
                    commit('UpdateMWSearchResult', response.data);
                    return response.data;
                }
            })
            .catch(error => {
                commit('updateAPIErrors', error);
                commit('UpdateMWSearchResult', null);
            })
    },

    getDealerSubscriptions({commit}, filterData) {
        return getDealerSubscriptionsAPI(filterData)
            .then(response => {
                if (response.status === 200) {
                    commit('updateInvalidDWCPUrlMessage', null);
                    return response.data;
                }
            })
            .catch(error => {
                commit('updateAPIErrors', error);
            })
    },

    getManageWebsiteProgramsFilter({commit}, data) {
        return getManageWebsiteProgramsFilterAPI(data)
            .then(response => {
                if (response.status === 200) {
                    commit('getManageWebsiteProgramsFilter', response.data);
                    return response.data;
                }
            })
            .catch(error => {
                commit('updateAPIErrors', error);
            })
    },

    getRegionListFilterByOem({commit}, data) {
        return getRegionListFilterAPI(data).then(response => {
            if (response.status === 200) {
                return response.data;
            }
            return [];
        }).catch(error => {
            commit('updateAPIErrors', error);
        });
    },

    getDistrictListFilterByOemAndRegion({commit}, data) {
        return getDistrictListFilterAPI(data).then(response => {
            if (response.status === 200) {
                return response.data;
            }
            return [];
        }).catch(error => {
            commit('updateAPIErrors', error);
        });
    },

    setManageProgramAction({commit}, data) {
        return setManageProgramActionAPI(data).then(response => {
            if (response.status === 200) {
                return response.data;
            }
            return [];
        }).catch(error => {
            commit('updateAPIErrors', error);
        });
    },

    getChangeableSubscriptions({commit}, data) {
        return getChangeableSubscriptions(data)
            .then(response => {
                if (response.status === 200) {
                    commit('getChangeableSubscriptions', response.data);
                }
            })
            .catch(error => {
                commit('updateAPIErrors', error);
            })
    },
    getSubscriptionOptions({commit}, data) {
        return getSubscriptionOptions(data)
            .then(response => {
                if (response.status === 200) {
                    commit('getSubscriptionOptions', response.data);
                }
            })
            .catch(error => {
                commit('updateAPIErrors', error);
            })
    },
    postChangeSubscriptions({commit}, data) {
        return postChangeSubscriptions(data)
            .then(response => {
                if (response.status === 200) {
                    commit('postChangeSubscriptions', response.data);
                }
            })
            .catch(error => {
                commit('updateAPIErrors', error);
            })
    },
    postManageSubscriptions(_, data) {
        return postManageSubscriptions(data);
    },
    getProductPricing({commit}, data) {
        return getProductPricingDetailAPI(data)
            .then(response => {
                if (response.status === 200) {
                    commit('getProductPricingDetail', response.data);
                }
            })
            .catch(error => {
                commit('updateAPIErrors', error);
            })
    },
    // ------------- Resources code start here ----------------
    deleteResource(_, data) {
        return deleteResourceAPI(data);
    },
    getResourcesFilterOptions({commit}, data) {
        return getResourcesFilterOptionsAPI(data)
            .then(response => {
                if (response.status === 200) {
                    commit('setResourcesFilterOptions', response.data);
                }
            })
            .catch(error => {
                commit('updateAPIErrors', error);
            })
    },
    getFilteredProducts({commit}, data) {
        return getFilteredProductsList(data)
            .then(response => {
                if (response.status === 200) {
                    commit('setAllowedProducts', response.data);
                }
            })
            .catch(error => {
                commit('updateAPIErrors', error);
            })
    },
    // ------------ Setup Enrollment Details Code Start Here ----------
    checkDealerEnrollmentData(_, data) {
        return getDealerEnrollmentDataAPI(data);
    },
    getDealerEnrollmentDataInfo({commit}) {
        return getDealerEnrollmentDataInfoAPI().then(response => {
            if (response.status === 200) {
                commit('SetupDealerEnrollmentDataInfo', response.data);
            }
        });
    },
    getManageEnrolmentDownloadManagerData({commit}) {
        return getManageEnrolmentDownloadManagerDataAPI().then(response => {
            if (response.status === 200) {
                commit('manageEnrolmentDownloadManagerData', response.data);
            }
        });
    },
    getSetupDealerEnrollmentData({commit}, data) {
        return getDealerEnrollmentDataAPI(data)
            .then(response => {
                if (response.status === 200) {
                    commit('SetupDealerEnrollmentData', response.data);
                }
            })
            .catch(error => {
                if (error.response.status === 400) {
                    let respData = error.response.data;
                    if (respData.message) {
                        if (data.addonId === process.env.VUE_APP_DD_ADDON_ID) {
                            router.push("/managePackagesDigitalDealer");
                        } else {
                            router.push("/managePackages");
                        }
                    }
                }
                commit('updateAPIErrors', error);
            })
    },
    postSetupDealerEnrollmentData(_, data) {
        return postDealerEnrollmentDataAPI(data);
    },
    sendDealerProviderInterestNotification(_, data) {
        return sendDealerProviderInterestNotificationAPI(data);
    },
    // ------------ Setup Enrollment Details Code End Here ----------

    //----------- Lead Viewer Request Credit and Download ADF API Start Here -------------
    getRequestCreditReason({commit}, leadData) {
        return getRequestCreditReasonAPI(leadData)
            .then(response => {
                if (response.status === 200) {
                    commit('updateRequestCreditReason', response.data);
                }
            })
            .catch(error => {
                commit('updateAPIErrors', error);
            })
    },
    postRequestCreditReason(_, data) {
        return postRequestCreditReasonAPI(data);
    },
    downloadLeadsADFFormat({commit}, data) {
        return downloadLeadsADFFormatAPI(data)
            .then(response => {
                if (response.status === 200) {
                    let filename = "";
                    let disposition = response.headers['content-disposition'];
                    if (disposition && disposition.indexOf('attachment') !== -1) {
                        let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                        let matches = filenameRegex.exec(disposition);
                        if (matches != null && matches[1]) {
                            filename = matches[1].replace(/['"]/g, '');
                        }
                    }

                    if (filename) {
                        filename = filename.replace(/:/g, '_');
                    }
                    const contentType = "text/xml";
                    const blobData = response.data;
                    let blob = new Blob([blobData], {type: contentType, charset: "UTF-8"});
                    if (typeof window.navigator.msSaveBlob !== 'undefined') {
                        window.navigator.msSaveBlob(blob, filename);
                    } else {
                        const blobURL = window.URL.createObjectURL(blob)
                        const tempLink = document.createElement('a');
                        tempLink.href = blobURL;

                        if (!filename) {
                            tempLink.setAttribute('download', `download.xml`);
                        } else {
                            tempLink.setAttribute('download', filename);
                        }
                        if (typeof tempLink.download === 'undefined') {
                            tempLink.setAttribute('target', '_blank');
                        }

                        document.body.appendChild(tempLink);
                        tempLink.click();
                        document.body.removeChild(tempLink);
                        window.URL.revokeObjectURL(blobURL);
                    }
                }
            })
            .catch(error => {
                commit('updateAPIErrors', error);
            })
    },
    findDispositionDetails(_, data) {
        return findDispositionDetailsAPI(data);
    },
    getDealerCommunicationDetail(_, data) {
        return getDealerCommunicationDetailAPI(data);
    },
    getLeadRecord(_, data) {
        return getLeadRecordAPI(data);
    },
    getLeadResponseTime(_, data) {
        return getLeadResponseTimeAPI(data);
    },
    notificationsUnsubscription(_, data) {
        return notificationsUnsubscriptionAPI(data);
    },
    //----------- Lead Viewer Request Credit and Download ADF API End Here --------------

    deletePreferencesLeadSettingsDetails(_, data) {
        return deletePreferencesLeadSettingsDetailsAPI(data);
    },

    // ------------------------------------------------------------------------------------
    generateReportForLeadType({commit}, data) {
        return generateReportAPI(data)
            .then(response => {
                if (response.status === 200) {
                    let respData = [];
                    let key = 'conciergeReportData';
                    respData = response.data && response.data.regionListNew;
                    if (data.ReportName === 'DealerSummaryAndDetailMatchReport') {
                        if (data.RequestPayLoad.leadTypes[0] === 'NEW') {
                            respData = response.data && response.data.regionListNew;
                            key = 'regionListNew';
                        } else if (data.RequestPayLoad.leadTypes[0] === 'CPO') {
                            respData = response.data && response.data.regionListCpo;
                            key = 'regionListCpo';
                        } else if (data.RequestPayLoad.leadTypes[0] === 'USED') {
                            respData = response.data && response.data.regionListUsed;
                            key = 'regionListUsed';
                        }
                    }
                    const finalData = {
                        key,
                        value: respData
                    };
                    commit('UpdateCheckGenerateReport', finalData);
                    if (data.ReportName === 'DealerSummaryAndDetailMatchReport') {
                        if (data.RequestPayLoad.leadTypes[0] === 'NEW') {
                            respData = response.data && response.data.dealerDetailMatchsNew;
                            key = 'dealerDetailMatchsNew';
                        } else if (data.RequestPayLoad.leadTypes[0] === 'CPO') {
                            respData = response.data && response.data.dealerDetailMatchsCpo;
                            key = 'dealerDetailMatchsCpo';
                        } else if (data.RequestPayLoad.leadTypes[0] === 'USED') {
                            respData = response.data && response.data.dealerDetailMatchsUsed;
                            key = 'dealerDetailMatchsUsed';
                        }
                        const additionalFinalData = {
                            key,
                            value: respData
                        };
                        commit('UpdateCheckGenerateReport', additionalFinalData);
                    }
                    commit('UpdateGenerateReportError', null);
                }
            })
            .catch(error => {
                commit('UpdateCheckGenerateReport', null);
                commit('UpdateGenerateReportError', error.response.data);
                commit('updateAPIErrors', error);
            })
    },
    // ------------------------------------------------------------------------------------

    // ------------------- Edit Dealer Enrollment Code Start ------------------------------ 
    getDealerEnrollmentDetails({commit}, dlCode) {
        return getDealerEnrollmentDetailsAPI(dlCode)
            .then(response => {
                if (response.status === 200) {
                    commit('getEditDealerEnrollmentDetails', response.data);
                }
            })
            .catch(error => {
                commit('updateAPIErrors', error);
            })
    },
    getAddonManageEnrollmentDetails({commit}, data) {
        return getAddonManageEnrollmentAPI(data)
            .then(response => {
                if (response.status === 200) {
                    commit('getAddonSetupEnrollmentDetails', response.data);
                }
            })
            .catch(error => {
                commit('updateAPIErrors', error);
            })
    },
    getSmartleadServiceAddonCustomParameterDetails({commit}, data) {
        return getSmartleadServiceAddonCustomParameterDetailsAPI(data)
            .then(response => {
                if (response.status === 200) {
                    commit('getAddonCustomParameterDetails', response.data);
                }
            })
            .catch(error => {
                commit('updateAPIErrors', error);
            })
    },
    getAddonCustomParametersToCopy({commit}, data) {
        return getAddonCustomParametersToCopyAPI(data)
            .then(response => {
                if (response.status === 200) {
                    commit('getAddonCustomParameterToCopy', response.data);
                }
            })
            .catch(error => {
                commit('updateAPIErrors', error);
            })
    },
    updateDealerSubscriptionCustomParams({commit}, data) {
        return updateDealerSubscriptionCustomParamsAPI(data)
            .then(response => {
              if (response.status === 200) {
                   commit('updateInvalidDWCPUrlMessage', null);
              }
              response
            })
            .catch(error => {
                if (error && error.response && error.response.data.errors) {
                    commit('updateInvalidDWCPUrlMessage', error.response.data.errors[0]);
                }else {
                    commit('updateAPIErrors', error);
                }
            })
    },
    updateAddonCustomParameters(_, data) {
        return updateAddonCustomParametersAPI(data);
    },
    subscribeAddonWithCopiedData(_, data) {
        return subscribeAddonWithCopiedDataAPI(data);
    },
    saveAddonParameters({commit}, data) {
        return saveAddonParametersAPI(data)
            .then(response => {
                if (response.status === 200) {
                    commit('getAddonParamResponse', response.data);
                }
            })
            .catch(error => {
                commit('updateAPIErrors', error);
            })
    },
    getSmartleadServiceAddonModels({commit}, data) {
        return getSmartleadServiceAddonModelsAPI(data)
            .then(response => {
                if (response.status === 200) {
                    commit('getAddonModelDetails', response.data);
                }
            })
            .catch(error => {
                commit('updateAPIErrors', error);
            });
    },
    updateDealerEnrollmentDetails({commit}, data) {
        return updateDealerEnrollmentDetailsAPI(data).catch(error => {
            commit('updateAPIErrors', error);
        });
    },
    otherUpdateDealerEnrollmentDetails(_, data) {
        return otherUpdateDealerEnrollmentDetailsAPI(data);
    },
    otherUpdateDealerBusinessTimeEnrollmentDetails(_, data) {
        return otherUpdateDealerBusinessTimeEnrollmentDetailsAPI(data);
    },
    updateProgramStatus(_, data) {
        return updateProgramStatusAPI(data);
    },
    updateAddonProgramStatus(_, data) {
        return getAddonProgramManageEnrollmentAPI(data);
    },
    // ------------------- Edit Dealer Enrollment Code End ------------------------------
    // -----------------
    getThresholds(_, oemId) {
        return getThresholdsAPI(oemId);
    },

    getAffinityDealerSalesReport({commit}, data) {
        return getAffinityDealerSalesReportAPI(data).then(response => {
            if (response.status === 200) {
                commit('AffinityDealerSalesReport', response.data);
                commit('updateAPIErrors', null);
            }
        }).catch(error => {
            commit('updateAPIErrors', error);
        });
    },
    getPeriodForReport(_, oemId) {
        return getPeriodForReportAPI(oemId);
    },
    generateReportDetails({commit}, data) {
        return generateReportDetailsAPI(data)
            .then(response => {
                if (response.status === 200) {
                    commit('UpdateGenerateReport', response.data);
                    commit('UpdateGenerateReportError', null);
                }
            })
            .catch(error => {
                commit('UpdateGenerateReport', null);
                commit('UpdateGenerateReportError', error.response.data);
                commit('updateAPIErrors', error);
            })
    },
    exportReportsRecentFullyConvertedDealerRanking({commit}, data) {
        return exportReportsRecentFullyConvertedDealerRankingAPI(data)
            .then(response => {
                if (response.status === 200) {
                    let filename = "";
                    let disposition = response.headers['content-disposition'];
                    if (disposition && disposition.indexOf('attachment') !== -1) {
                        let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                        let matches = filenameRegex.exec(disposition);
                        if (matches != null && matches[1]) {
                            filename = matches[1].replace(/['"]/g, '');
                        }
                    }
                    if (filename) {
                        filename = filename.replace(/:/g, '_');
                    }
                    const contentType = response.headers['content-type'];
                    let blob = new Blob([response.data], {type: contentType, charset: "UTF-8"});
                    if (typeof window.navigator.msSaveBlob !== 'undefined') {
                        window.navigator.msSaveBlob(blob, filename);
                    } else {
                        const blobURL = window.URL.createObjectURL(blob);
                        // window.open(blobURL);
                        const tempLink = document.createElement('a');
                        tempLink.href = blobURL;
                        if (!filename) {
                            tempLink.setAttribute('download', `${data.reportName}.${data.exportFormat}`);
                        } else {
                            tempLink.setAttribute('download', filename);
                        }
                        if (typeof tempLink.download === 'undefined') {
                            tempLink.setAttribute('target', '_blank');
                        }
                        document.body.appendChild(tempLink);
                        tempLink.click();
                        document.body.removeChild(tempLink);
                        window.URL.revokeObjectURL(blobURL);
                    }
                }
            })
            .catch(error => {
                commit('updateAPIErrors', error);
            })
    },
    // -----------------
    //Contact Us Code Starts Here - API Call
    submitContactForm({commit}, data) {
        return submitContactFormAPI(data)
            .then(response => {
                if (response.status === 200) {
                    // commit('updateUserProfileDetails', response.data);
                }
            })
            .catch(error => {
                commit('updateAPIErrors', error);
            })
    },
    //Contact Us Code Ends Here
    submitResponseTimeOverride({commit}, data) {
        return submitResponseTimeOverrideAPI(data);
    },
    // --------------------------------------------------------------------------------------
    // ------------------- Edit Dealer Notification Code Start ------------------------------
    updatePortalNotificationSubscription(_, data) {
        return updatePortalNotificationSubscriptionAPI(data);
    },

    // Compliance API call Start Here
    //----------------------------------------------------------------------------------------
    generateComplianceDataRecord({commit}, data) {
        return generateComplianceDataRecordAPI(data)
            .then(response => {
                if (response.status === 200) {
                    commit('updateComplianceDataRecordState', response.data);
                }
            })
            .catch(error => {
                commit('updateAPIErrors', error);
            });
    },
    generateComplianceScoresReport({commit}, data) {
        return generateComplianceScoresReportAPI(data)
            .then(response => {
                if (response.status === 200) {
                    commit('updateComplianceAuditScoresDataRecordsState', response.data);
                }
            })
            .catch(error => {
                commit('updateAPIErrors', error);
            });
    },
    prepareCreateReport({commit}, data) {
        return prepareCreateReportAPI(data)
            .then(response => {
                if (response.status === 200) {
                    commit('updateAPIErrors', null);
                    commit('UpdateComplianceDataReportDetails', response.data);
                }
            })
            .catch(error => {
                commit('UpdateComplianceDataReportDetails', null);
                commit('updateAPIErrors', error);
            });
    },
    prepareCreateCrmComplianceReport({commit}, data) {
        return prepareCreateCrmComplianceReportAPI(data)
            .then(response => {
                if (response.status === 200) {
                    commit('updateAPIErrors', null);
                    commit('UpdateComplianceDataReportDetails', response.data);
                }
            })
            .catch(error => {
                commit('UpdateComplianceDataReportDetails', null);
                commit('updateAPIErrors', error);
            });
    },
    getOEMRegionsDetails(_, oemId) {
        return getOEMRegionsDetailsAPI(oemId);
    },
    getRegionsDistrictsDetails(_, regionId) {
        return getRegionsDistrictsDetailsAPI(regionId);
    },
    createNewReport({commit}, data) {
        return createNewReportAPI(data).then(response => {
            if (response.status === 200) {
                commit('UpdateComplianceDataReportDetails', response.data);
            }
        })
            .catch(error => {
                commit('updateAPIErrors', error);
            });
    },
    updateViewedReport(_, data) {
        return updateViewedReportAPI(data);
    },
    uploadFiles(_, data) {
        return uploadFilesAPI(data);
    },
    removeUploadFile(_, data) {
        return removeUploadFileAPI(data);
    },
    zipCodeBulkImport(_, data) {
        return zipCodeBulkImportAPI(data);
    },
    zipCodeBulkImportSave(_, data) {
        return zipCodeBulkImportSaveAPI(data);
    },
    resetComplianceDealerReport({commit}) {
        commit('UpdateComplianceDataReportDetails', null);
    },
    getZipCodeBulkImportResult({commit}, data) {
        return getZipCodeBulkImportResultAPI(data)
            .then(response => {
                if (response.status === 200) {
                    commit('updateAPIErrors', null);
                    commit('UpdateZipCodeBulkImportResult', response.data);
                }
            })
            .catch(error => {
                commit('UpdateZipCodeBulkImportResult', null);
                commit('updateAPIErrors', error);
            });
    },
    resetComplianceAggregatedReport({commit}) {
        commit('UpdateComplianceAggregatedReportDetails', null);
    },
    resetCrmComplianceAggregatedReport({commit}) {
        commit('UpdateCrmComplianceAggregatedReportDetails', null);
    },
    getComplianceDealerReportDetails({commit}, data) {
        return getComplianceDealerReportDetailsAPI(data)
            .then(response => {
                if (response.status === 200) {
                    commit('UpdateComplianceDataReportDetails', response.data);
                }
            })
            .catch(error => {
                commit('updateAPIErrors', error);
            })
    },
    generateComplianceDealerReport({commit}, data) {
        return complianceDealerReportAPI(data)
            .then(response => {
                if (response.status === 200) {
                    commit('updateAPIErrors', null);
                    commit('UpdateComplianceDataReportDetails', response.data);
                }
            })
            .catch(error => {
                commit('UpdateComplianceDataReportDetails', null);
                commit('updateAPIErrors', error);
            });
    },
    generateNonCompliantReport({commit}, data) {
        return getNonCompliantReportDetailsAPI(data)
            .then(response => {
                if (response.status === 200) {
                    commit('updateAPIErrors', null);
                    commit('UpdateNonComplaintReportDetails', response.data);
                }
            })
            .catch(error => {
                commit('UpdateNonComplaintReportDetails', null);
                commit('updateAPIErrors', error);
            });
    },
    generateCrmNonCompliantReport({commit}, data) {
        return getCrmNonCompliantReportDetailsAPI(data)
            .then(response => {
                if (response.status === 200) {
                    commit('updateAPIErrors', null);
                    commit('UpdateCrmNonComplaintReportDetails', response.data);
                }
            })
            .catch(error => {
                commit('UpdateCrmNonComplaintReportDetails', null);
                commit('updateAPIErrors', error);
            });
    },
    generateComplianceAggregatedReport({commit}, data) {
        return getComplianceAggregateReportDetailsAPI(data)
            .then(response => {
                if (response.status === 200) {
                    commit('updateAPIErrors', null);
                    commit('UpdateComplianceAggregatedReportDetails', response.data);
                }
            })
            .catch(error => {
                commit('UpdateComplianceAggregatedReportDetails', null);
                commit('updateAPIErrors', error);
            });
    },
    generateCrmComplianceAggregatedReport({commit}, data) {
        return getCrmComplianceAggregateReportDetailsAPI(data)
            .then(response => {
                if (response.status === 200) {
                    commit('updateAPIErrors', null);
                    commit('UpdateCrmComplianceAggregatedReportDetails', response.data);
                }
            })
            .catch(error => {
                commit('UpdateCrmComplianceAggregatedReportDetails', null);
                commit('updateAPIErrors', error);
            });
    },
    getDealerReportFilter({commit}, data) {
        return getDealerReportFilterAPI(data)
            .then(response => commit('UpdateComplianceDealerReportDetails', response.data))
            .catch(() => commit('UpdateComplianceDealerReportDetails', null));
    },
    //---------------------------------------------------------------------------------------
    // Compliance API call End Here
    getOfferProvidersPDFUrl(_, data) {
        return getOfferProvidersPDFAPI(data);
    },

    getManageWebsiteProgramsReport({commit}, data) {
        return getManageWebsiteProgramsReportAPI(data)
            .then(response => {
                if (response.status === 200) {
                    const dt = new Date();
                    const month = dt.toLocaleString('en-US', {month: 'short'});
                    const day = dt.toLocaleString('en-US', {weekday: 'short'});
                    const year = dt.getUTCFullYear();
                    const date = dt.getUTCDate();
                    let filename = "";
                    let disposition = response.headers['content-disposition'];
                    if (disposition && disposition.indexOf('attachment') !== -1) {
                        let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                        let matches = filenameRegex.exec(disposition);
                        if (matches != null && matches[1]) {
                            filename = matches[1].replace(/['"]/g, '');
                        }
                    }

                    if (filename) {
                        filename = filename.replace(/:/g, '_');
                    }
                    if (typeof window.navigator.msSaveBlob !== 'undefined') {
                        let blobData = response.data;
                        let blob = new Blob([blobData], {type: 'application/octet-stream'});
                        window.navigator.msSaveBlob(blob, filename);
                    } else {
                        const blobURL = window.URL.createObjectURL(new Blob([response.data], {type: 'application/octet-stream'}));
                        const tempLink = document.createElement('a');
                        tempLink.href = blobURL;

                        if (!filename) {
                            tempLink.setAttribute('download', `Payload-${day}_${month}_${date}_${year}.${data.type.toLowerCase()}`);
                        } else {
                            tempLink.setAttribute('download', filename);
                        }
                        if (typeof tempLink.download === 'undefined') {
                            tempLink.setAttribute('target', '_blank');
                        }

                        document.body.appendChild(tempLink);
                        tempLink.click();
                        document.body.removeChild(tempLink);
                        window.URL.revokeObjectURL(blobURL);
                    }
                }
            })
            .catch(error => {
                commit('updateAPIErrors', error);
            })
    },
};
